import { Injectable } from '@angular/core';
import { CoreSharedState } from '@core/state/core-shared.state';

export type LoadingSharedState = {
  isLoading: boolean | any;
}

@Injectable({ providedIn: 'root' })
export class AppLoadingOverlayService extends CoreSharedState<LoadingSharedState> {
  constructor() {
    super({ isLoading: false });
  }

  override setState(state: LoadingSharedState | null): void {
    super.setState(state);
    super.pushStateToSubscriber();
  }
}
